import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { LoginService } from 'src/services/login.service';
import { RevendaCadService } from 'src/services/revenda-cad.service';
import { BrMaskerModule } from 'br-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { PreAuthGuard } from '../../guards/pre-auth.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [PreAuthGuard],
    canLoad: [PreAuthGuard]
  }
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrMaskerModule,
    NgxPaginationModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    LoginService,
    RevendaCadService
  ]
})
export class UserPagesModule {
}
