import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { LoginService } from 'src/services/login.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  user: any;

  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    } else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }
  constructor(config: NgbDropdownConfig,  private router: Router, private auth: LoginService) {
    config.placement = 'bottom-right';
    this.checkUser();
  }
  async checkUser () {
    this.user = await (await this.auth.user()).json();
  }
  ngOnInit() {
  }

  logout() {
    const tipoAcesso = localStorage.getItem('tipoAcesso');
    if ('revenda' === tipoAcesso) {
      localStorage.removeItem('currentUserAnimal');
      localStorage.removeItem('tipoAcesso');
      this.router.navigate(['/afiliado-pages/login']);
    } else if ('admin' === tipoAcesso) {
      localStorage.removeItem('currentUserAnimal');
      localStorage.removeItem('tipoAcesso');
      this.router.navigate(['/user-pages/login']);
    }
  }

  nomeUsuario() {
    let nomeUsuario = '';
    if (this.user) {
      const tipoAcesso = localStorage.getItem('tipoAcesso');
      if ('revenda' === tipoAcesso) {
        nomeUsuario = this.user.nome ? this.user.nome : this.user.name;
      } else {
        nomeUsuario = this.user.name ? this.user.name : this.user.nome;
      }
    }
    return nomeUsuario;
  }
}
