import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class RevendaCadService {

    constructor(private apiService: ApiService) {

    }

    login(params) {
        return this.apiService.post('/api/authenticate-admin', params, {})
    }

    user() {
        return this.apiService.get('/api/admin/me', {}, {})
    }

    sendCode(params) {
        return this.apiService.post('/api/send-code', params, {})
    }

    validaEmail(params) {
        return this.apiService.post('/api/valida-email', params, {})
    }

    validaCode(params) {
        return this.apiService.post('/api/valida-code', params, {})
    }

    reset(params) {
        return this.apiService.post('/api/reset-senha', params, {})
    }

}