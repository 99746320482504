import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    const user = localStorage.getItem('currentUserAnimal');
    if (!user) {
      if (localStorage.getItem('tipoAcesso') === 'revenda') {
        this.router.navigate(['/afiliado-pages/login']);
        return true;
      }
      this.router.navigate(['/user-pages/login']);
      return false;
    }
    return true;
  }

  canLoad(route: Route): boolean {
    const user = localStorage.getItem('currentUserAnimal');
    if (!user) {
      if (localStorage.getItem('tipoAcesso') === 'revenda') {
        this.router.navigate(['/revenda-pages/login']);
        return true;
      }
      this.router.navigate(['/user-pages/login']);
      return false;
    }
    return true;
  }
}
