import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  // páginas de login
  {
    path: 'user-pages',
    loadChildren: () => import('./user-pages/user-pages.module').then(m => m.UserPagesModule)
  },
  {
    path: 'afiliado-pages',
    loadChildren: () => import('./revenda-pages/revenda-pages.module').then(m => m.RevendaPagesModule),
  },
  //
  //
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'resultados',
    loadChildren: './resultados/resultados.module#ResultadosModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'horario-sorteio',
    loadChildren: './horario-sorteio/horario-sorteio.module#HorarioSorteioModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'afiliados',
    loadChildren: './revenda/revenda.module#RevendaModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'cupom',
    loadChildren: './cupom/cupom.module#CupomModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'transferencia',
    loadChildren: './transferencia/transferencia.module#TransferenciaModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'geral',
    loadChildren: './geral/geral.module#GeralModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'usuarios',
    loadChildren: './usuarios/usuarios.module#UsuariosModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'premiados',
    loadChildren: './premiados/premiados.module#PremiadosModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'apostas',
    loadChildren: './apostas/apostas.module#ApostasModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'premios',
    loadChildren: './premios/premios.module#PremiosModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'rifas',
    loadChildren: './rifas/rifas.module#RifasModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'extracao',
    loadChildren: './extracao/extracao.module#ExtracaoModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'recarga',
    loadChildren: './recarga/recarga.module#RecargaModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'financeiro',
    loadChildren: './financeiro/financeiro.module#FinanceiroModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'cotacao',
    loadChildren: './cotacao/cotacao.module#CotacaoModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {
    path: 'recarga-transferencias',
    loadChildren: './recarga/recarga-transferencia.module#RecargaTransferenciaModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'saldo-contas',
    loadChildren: './saldo-conta/saldo-conta.module#SaldoContaModule',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard]
  },
  {path: '**', redirectTo: 'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
