import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';

import { NgbActiveModal, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpModule } from '@angular/http';
import { ApiService } from 'src/services/api.service';
import { LoginService } from 'src/services/login.service';
import { RangeSliderModule } from 'ngx-range-slider';
import { NgxPaginationModule } from 'ngx-pagination';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrMaskerModule } from 'br-mask';
import { NgxCoolDialogsModule } from 'ngx-cool-dialogs';
import { TooltipModule } from 'ngx-tooltip';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from '../interceptors/authorization.interceptor';
import { QuillModule } from 'ngx-quill';
import { TOOLBAR_QUILL } from '../util/constants';
import { NgxLoadingModule } from 'ngx-loading';

registerLocaleData(ptBr, 'pt')

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    NgbModalModule,
    NgbModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    HttpModule,
    NgxPaginationModule,
    NgxLoadingModule,
    RangeSliderModule,
    BrMaskerModule,
    TooltipModule,
    BsDatepickerModule.forRoot(),
    NgxCoolDialogsModule.forRoot(),
    QuillModule.forRoot({
      modules: {
        syntax: true,
        toolbar: TOOLBAR_QUILL
      }
    }),
  ],
  providers: [
    ApiService,
    LoginService,
    NgbActiveModal,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
