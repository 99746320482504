
import { Injectable } from "@angular/core";
import { ApiService } from 'src/services/api.service';


@Injectable()
export class DashboardService {
    constructor(private apiService: ApiService) { }

    save(params) {
        return this.apiService.post("/api/pagamento", params, {});
    }

    aprovar(params) {
        return this.apiService.post("/api/pagamento/aprovar", params, {});
    }

    reprovar(params) {
        return this.apiService.post("/api/pagamento/reprovar", params, {});
    }

    show(id) {
        return this.apiService.get("/api/user/show", {id: id}, {});
    }

    retornaLista(params) {  
        return this.apiService.get("/api/pagamento/list", params, {});
    }

    retornaListaApostas(params) {  
        return this.apiService.get("/api/aposta/list", params, {});
    }

    retornaListaRelatorio(params = {}) {  
        return this.apiService.get("/api/relatorio/list", params, {});
    }


    retornaItem(params) {
        return this.apiService.get("/api/pagamento/show", params, {});
    }

    upload(params) {
        return this.apiService.postUpload("/api/pagamento/upload", params, {});
    }

    delete(params) {    
        return this.apiService.post("/api/pagamento/delete", params, {});
    }

    ativa(params) {    
        return this.apiService.post("/api/pagamento/ativa", params, {});
    }
}
