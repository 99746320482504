
import { Injectable } from "@angular/core";
import { ApiService } from 'src/services/api.service';


@Injectable()
export class TransferenciaService {
    constructor(private apiService: ApiService) { }

    save(params) {
        return this.apiService.post("/api/transferencia", params, {});
    }

    aprovar(params) {
        return this.apiService.post("/api/transferencia/aprovar", params, {});
    }

    executar(params) {
        return this.apiService.post("/api/transferencia/executar", params, {});
    }

    estornar(params) {
        return this.apiService.post("/api/transferencia/estornar", params, {});
    }

    reprovar(params) {
        return this.apiService.post("/api/transferencia/reprovar", params, {});
    }

    show(id) {
        return this.apiService.get("/api/user/show_admin", {id: id}, {});
    }

    retornaLista(params) {  
        return this.apiService.get("/api/transferencia/list", params, {});
    }

    retornaItem(params) {
        return this.apiService.get("/api/transferencia/show", params, {});
    }

    upload(params) {
        return this.apiService.postUpload("/api/transferencia/upload", params, {});
    }

    delete(params) {    
        return this.apiService.post("/api/transferencia/delete", params, {});
    }

    ativa(params) {    
        return this.apiService.post("/api/transferencia/ativa", params, {});
    }

    retornaListaTransferenciasAbertas() {
        return this.apiService.get("/api/transferencia/list-tranferencias-abertas", null, {});
    }
    
    retornaValorTotal(params = {}) {  
        return this.apiService.get("/api/relatorio/list", params, {});
    }
}
