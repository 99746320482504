import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreAuthGuard implements CanLoad, CanActivate {

  constructor(
    private router: Router
  ) {
  }

  async canLoad(route: Route): Promise<boolean> {
    const user = localStorage.getItem('currentUserAnimal');
    if (user) {
      if (localStorage.getItem('tipoAcesso') === 'revenda') {
        this.router.navigate(['/revenda/show']);
        return true;
      }
      await this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean> | Promise<boolean> | boolean {
    const user = localStorage.getItem('currentUserAnimal');
    if (user) {
      if (localStorage.getItem('tipoAcesso') === 'revenda') {
        this.router.navigate(['/revenda/show']);
        return true;
      }
      this.router.navigate(['dashboard']);
      return false;
    }
    return true;
  }
}
