import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DashboardService } from './dashboard.service';
import * as moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


defineLocale('pt-br', ptBrLocale);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss', './dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DashboardService],
})
export class DashboardComponent implements OnInit {

  list: any = {
    docs: []
  }
  listAp: any = {
    docs: []
  }

  formData = {
    range: [
      new Date(),
      new Date()
    ],
    sorteio: null,
    tipo: null,
    premio: null,
  }
  page: number = 1;
  currentItem: any = null;
  dash: any = {
    'media_aposta': 0,
    'media_premio': 0,
    'tipos': [],
    'commisao': 0,
    'total_apostas': 0,
    'total_premios': 0,
    'total_recarga': 0,
    'saldo_carteira_empresa': 0,
    'percentual_premio_aposta': 0,
    'total_users': 0,
    'total_solicitacoes': 0,
    'total_retirada': 0,
    'total_bonus': 0,
    'total_carteira_clientes': 0,
    'percentual_saque_recarga': 0,
  };
  constructor(
    private dashboardService: DashboardService,
    private localeService: BsLocaleService,
    private modalService: NgbModal,
  ) {
    this.localeService.use('pt-br');
  }

  ngOnInit() {

    this.retornaLista(1);
    this.retornaListaAposta(1);
    this.loadDash();
    // this.showModal();
  }

  async retornaLista(page) {
    this.list = {
      docs: []
    };
    var params = {
      start: moment(this.formData.range[0]).format('YYYY-MM-DD'),
      end: moment(this.formData.range[1]).format('YYYY-MM-DD'),
      page: null,
    };
    params.page = page;
    let result: any = await this.dashboardService.retornaLista(params);
    if (result._body) {
      this.list = JSON.parse(result._body)
    }
  }

  filtroPremio = [];
  marcaPremio(valor) {
    let index = this.filtroPremio.find(a => a == valor);
    if (!index) {
      this.filtroPremio.push(valor);
    } else {
      this.filtroPremio.splice(index, 1);
    }
    this.formData.premio = this.filtroPremio.join(',');
    this.changeValues();
  }

  async retornaListaAposta(page) {
    this.listAp = {
      docs: []
    };
    var params = {
      start: moment(this.formData.range[0]).format('YYYY-MM-DD'),
      end: moment(this.formData.range[1]).format('YYYY-MM-DD'),
      page: null,
      tipo: this.formData.tipo,
      sorteio: this.formData.sorteio,
      premio: this.formData.premio
    };
    params.page = page;
    let result: any = await this.dashboardService.retornaListaApostas(params);
    if (result._body) {
      this.listAp = JSON.parse(result._body)
    }
  }

  async loadDash() {
    let result = await this.dashboardService.retornaListaRelatorio({
      start: moment(this.formData.range[0]).format('YYYY-MM-DD'),
      end: moment(this.formData.range[1]).format('YYYY-MM-DD'),
      tipo: this.formData.tipo,
      sorteio: this.formData.sorteio,
      premio: this.formData.premio
    });
    this.dash = result.json();
  }

  getBrValue(value) {
    if (value) {
      return value.toString().replace(/\,/g, '').replace(/\./g, ',');
    }
    return value;
  }

  getValue(value) {
    return (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    })
  }

  changeValues() {
    this.loadDash();
    this.retornaListaAposta(1);
    this.retornaLista(1);
  }

}
