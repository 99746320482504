import { environment } from '../environments/environment';
import { QuillToolbarConfig } from 'ngx-quill/src/quill-editor.interfaces';

export const URL_BASE = environment.apiUrl;
export const URL_JOGO = environment.appUrl;
export const TOOLBAR_QUILL: QuillToolbarConfig = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'font': [] }],
  ['blockquote', 'code-block'],
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  [{ 'align': [] }],
  ['clean'],                                         // remove formatting button
  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  // ['link', 'image']                         // link and image, video
];
