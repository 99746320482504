import { Injectable } from '@angular/core';
import { NgxLoadingService } from 'ngx-loading';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  constructor(private ngxLoadingService: NgxLoadingService) {
  }

  $loading = new Subject<boolean>();

  show() {
    this.$loading.next(true);
  }

  hide() {
    this.$loading.next(false);
  }
}
