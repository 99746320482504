import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable()
export class LoginService {

    constructor(private apiService: ApiService) {

    }

    login(params) {
        return this.apiService.post('/api/authenticate-admin', params, {});
    }

    loginRevenda(params) {
        return this.apiService.post('/api/authenticate-revenda', params, {});
    }

    async salvarRevenda(params, token) {
        return this.apiService.postDirect('/api/revenda/salvar-revenda', params, token);
    }

    async findRevenda(params, token) {
        return this.apiService.getDirect('/api/user/find-revenda', params, token);
    }


    user() {
        const tipoAcesso = localStorage.getItem('tipoAcesso');
        if ('revenda' === tipoAcesso) {
            return this.apiService.get('/api/revenda/me', null, {});
        } else {
            return this.apiService.get('/api/admin/me', {}, {});
        }
    }

    sendCode(params) {
        return this.apiService.post('/api/send-code', params, {});
    }

    validaEmail(params) {
        return this.apiService.post('/api/valida-email', params, {});
    }

    validaCode(params) {
        return this.apiService.post('/api/valida-code', params, {});
    }

    reset(params) {
        return this.apiService.post('/api/reset-senha', params, {});
    }

}
