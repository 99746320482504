import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'AnimalBet';

  showSidebar = true;
  showNavbar = true;
  showFooter = true;
  loading = false;

  constructor(private router: Router, private loadinService: LoadingService) {
    this.loadinService.$loading.subscribe((data) => {
      this.loading = data;
    });
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if ((event.url === '/user-pages/login') ||
          (event.url === '/afiliado-pages/login') ||
          (event.url === '/user-pages/register') ||
          (event.url === '/error-pages/404')
          || (event.url === '/error-pages/500')) {
          this.showSidebar = false;
          this.showNavbar = false;
          this.showFooter = false;
        } else {
          const tipoAcesso = localStorage.getItem('tipoAcesso');
          if ('revenda' === tipoAcesso) {
            this.showSidebar = false;
          } else {
            this.showSidebar = true;
          }
          this.showNavbar = true;
          this.showFooter = true;
        }
      }
    });

    // let jwtInfo = JSON.parse(localStorage.getItem('currentUserAnimal'));
    // if (!jwtInfo) {
    //   let tipoAcesso = localStorage.getItem("tipoAcesso");
    //   if ("revenda" === tipoAcesso) {
    //     this.router.navigate(['/afiliado-pages/login']);
    //   } else if ("admin" === tipoAcesso) {
    //     this.router.navigate(['/user-pages/login']);
    //   }
    // }
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
