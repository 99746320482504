import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions} from '@angular/http';
import {URL_BASE} from 'src/util/constants';

@Injectable()
export class ApiService {
  constructor(private http: Http) {
  }

  post(api, params, header) {
    header = this.getHeaders();
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.post(url, params, options).toPromise();
  }

  postFormData(api, formData, header) {
    header = this.getHeadersFormData(formData);
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.post(url, formData, options).toPromise();
  }

  putFormData(api, formData, header) {
    header = this.getHeadersFormData(formData);
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.put(url, formData, options).toPromise();
  }

  postDirect(api, params, token) {
    const header = this.getHeadersDirect(token);
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.post(url, params, options).toPromise();
  }

  postUpload(api, params, header) {
    header = this.getHeadersUpload();
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.post(url, params, options).toPromise();
  }

  patch(api, params, header) {
    header = this.getHeaders();
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.patch(url, params, options).toPromise();
  }

  put(api, params, header) {
    header = this.getHeaders();
    const options = new RequestOptions({headers: header});
    const url = URL_BASE + api;
    return this.http.put(url, params, options).toPromise();
  }

  getDirect(api, params, token) {
    const header = this.getHeadersDirect(token);
    const options = new RequestOptions({headers: header});
    const encodeParams = this.prepareParams(params);
    const url = URL_BASE + api + encodeParams;
    return this.http.get(url, options).toPromise();
  }

  download(api, params, header) {
    header = this.getHeaders();
    const options = new RequestOptions({headers: header, responseType: 3});
    const encodeParams = this.prepareParams(params);
    const url = URL_BASE + api + encodeParams;
    return this.http.get(url, options).toPromise();
  }

  get(api, params, header) {
    header = this.getHeaders();
    const options = new RequestOptions({headers: header});
    const encodeParams = this.prepareParams(params);
    const url = URL_BASE + api + encodeParams;
    return this.http.get(url, options).toPromise();
  }

  delete(api, params, header) {
    if (!header) {
      header = this.getHeaders();
    }
    const options = new RequestOptions({headers: header});
    const encodeParams = this.prepareParams(params);
    const url = URL_BASE + api + encodeParams;
    return this.http.delete(url, options).toPromise();
  }

  getUri(api, params, header) {
    if (!header) {
      header = this.getHeaders();
    }
    const options = new RequestOptions({headers: header});
    const encodeParams = this.prepareParams(params);
    const url = api + encodeParams;
    return this.http.get(url, options).toPromise();
  }

  prepareParams(params) {
    const str = [];
    for (const p in params) {
      if (params[p]) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(params[p]));
      }
    }
    return '?' + str.join('&');
  }

  getHeaders() {
    const user = JSON.parse(localStorage.getItem('currentUserAnimal'));

    if (user && user.token) {
      return new Headers({
        'Content-Type': 'application/json',
        'x-access-token': user.token
      });
    } else {
      return new Headers({
        'Content-Type': 'application/json'
      });
    }
  }
  getHeadersFormData(formData: any) {
    const user = JSON.parse(localStorage.getItem('currentUserAnimal'));

    if (user && user.token) {
      return new Headers({
        'x-access-token': user.token

      });
    } else {
      return new Headers({
      });
    }
  }

  getHeadersDirect(token) {
    return new Headers({
      'Content-Type': 'application/json',
      'x-access-token': token
    });
  }

  user() {
    const tipoAcesso = localStorage.getItem('tipoAcesso');
    if ('revenda' === tipoAcesso) {
      return this.get('/api/revenda/me', {}, {});
    } else {
      return this.get('/api/admin/me', {}, {});
    }
  }

  getHeadersUpload() {
    const user = JSON.parse(localStorage.getItem('currentUserAnimal'));

    if (user && user.token) {
      return new Headers({
        'Content-Type': 'multipart/form-data',
        'x-access-token': user.token
      });
    }
  }
}
