import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { LoginService } from 'src/services/login.service';
import { Router } from '@angular/router';
import { TransferenciaService } from '../transferencia/transferencia.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  providers: [
    LoginService,
    TransferenciaService
  ]
})
export class SidebarComponent implements OnInit {
  @ViewChild('content', {static: false}) content: Component;
  public transferencias: any;
  public hasTransferencia: any;

  constructor(
    private auth: LoginService,
    private router: Router,
    private modalService: NgbModal,
    private transferenciaService: TransferenciaService,
  ) {
    this.checkUser();
  }

  ngOnInit() {
  }

  async checkUser () {
    try {
      const user = await this.auth.user();
      if (!user) {
        localStorage.removeItem('currentUserAnimal');
        const tipoAcesso = localStorage.getItem('tipoAcesso');
        if ('revenda' === tipoAcesso) {
          this.router.navigate(['/afiliado-pages/login']);
        } else if ('admin' === tipoAcesso) {
          this.router.navigate(['/user-pages/login']);
        }
      } else {
        const tipoAcesso = localStorage.getItem('tipoAcesso');
        if (tipoAcesso === 'admin') {
          const transferencias: any = await this.transferenciaService.retornaListaTransferenciasAbertas();
          const body = JSON.parse(transferencias._body);

          this.hasTransferencia = false;
          body.forEach(item => {
            if (item.transferencias.length > 0) {
              this.hasTransferencia = true;
            }
          });
          if (this.hasTransferencia) {
            this.transferencias = body;
            await this.open();
          }
        }
      }
    } catch (e) {
      localStorage.removeItem('currentUserAnimal');
      const tipoAcesso = localStorage.getItem('tipoAcesso');
      if ('revenda' === tipoAcesso) {
        this.router.navigate(['/afiliado-pages/login']);
      } else if ('admin' === tipoAcesso) {
        this.router.navigate(['/user-pages/login']);
      }
    }
  }

  getValue(value) {
    return (value / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    });
  }

  open() {
    this.modalService.open(this.content, {size: 'xl', ariaLabelledBy: 'modal-basic-title'});
  }
}
