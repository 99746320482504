import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastrService: ToastrService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const token = this.authService.getToken();
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`
    //     }
    //   });
    // }

    // @ts-ignore
    return next.handle(request).pipe(
      catchError(async (err) => {
        if (err.status === 401 || err.status === 403) {
          await this.toastrService.error('Por favor, realize login novamente.', 'Sessão expirada');
          localStorage.removeItem('currentUserAnimal');
          if (localStorage.getItem('tipoAcesso') === 'revenda') {
            this.router.navigate(['/revenda/show']);
            return;
          }
          await this.router.navigate(['/user-pages/login']);
        }
        return throwError(err);
      })
    );
  }

}
