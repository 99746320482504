import { Component, ViewChild, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { LoginService } from 'src/services/login.service';
import { $ } from 'protractor';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  @ViewChild('cpf', {static: false}) cpf: ElementRef;

  loading = false;
  formData = {
    email: '',
    password: '',
    emailrec: '',
    recovery: '',
    cod: '',
    confSenha: '',
    senharec: '',
    admin: 'true',
    revenda: false
  };

  formDataRev = {
    name: null,
    cpf: null,
    valor:0
  };
  scLogin = true;
  scRecovery = false;
  scRecoveryCode = false;
  admin = true;
  revenda = false;
  scRecoveryPass = false;
  userInfo: any = {};

  list: any = {
      docs:[]
  }

  constructor(
    private toastr: ToastrService,
    private loadinService: LoadingService,
    private loginService: LoginService,
    private router: Router
  ) { }

  async login() {
    this.loadinService.show();
    if (await this.validate()) {
      try {
          const result: any = await this.loginService.loginRevenda({ email: this.formData.email, password: this.formData.password });
          let user = result;
          if (result._body) {
            user = JSON.parse(result._body);
          }
          if (user && user.token) {
            this.userInfo = user;
            localStorage.setItem('currentUserAnimal', JSON.stringify(user));
            localStorage.setItem('tipoAcesso', 'revenda');
            this.router.navigate(['/afiliados/show']);
          }
      } catch (e) {
        this.toastr.error('Ops, todo mundo erra!');
      }
    }
    this.loadinService.hide();
  }

  sair()  {
    this.scLogin = true;
  }


  async validate() {
    if (!this.formData.email) {
      this.toastr.error('E-mail é obrigatorio!');
      return false;
    }
    if (!await this.IsEmail(this.formData.email)) {
      this.toastr.error('O e-mail informado é inválido.');
      return false;
    }
    if (!this.formData.password) {
      this.toastr.error('Senha é obrigatorio!');
      return false;
    }
    return true;
  }

  async validateSave() {
    if (!this.formDataRev.name) {
      this.toastr.error('Nome é obrigatorio!');
      return false;
    }
    if (!this.formDataRev.cpf) {
      this.toastr.error('CPF é obrigatorio!');
      return false;
    }
    /*if (!this.formDataRev.valor) {
      this.toastr.error('Valor é obrigatorio!');
      return false;
    }*/
    return true;
  }

  async salvar() {
    if (await this.validateSave()) {
      console.log(this.formDataRev)
        const result: any = await this.loginService.salvarRevenda({ name: this.formDataRev.name,
           cpf: this.cpf.nativeElement.value,
           code: this.userInfo.user.codigo,
            valor: this.formDataRev.valor }, this.userInfo.token);
        let user = result;
        user = JSON.parse(result._body);
        if (user._id) {
          this.toastr.success('Pré cadastro realizado.');
          this.formDataRev = {
            name: null,
            cpf: null,
            valor: 0,
          }
          this.retornaLista(1)
        } else {
            this.toastr.error(user.msg);
        }
    }
  }

  recovery() {
    this.scLogin = false;
    this.scRecovery = true;
    this.scRecoveryCode = false;
    this.scRecoveryPass = false;
  }

  openLogin() {
    this.scLogin = true;
    this.scRecovery = false;
    this.scRecoveryCode = false;
    this.scRecoveryPass = false;
  }

  recoveryCode() {
    this.scLogin = false;
    this.scRecovery = false;
    this.scRecoveryCode = true;
    this.scRecoveryPass = false;
  }

  recoveryPass () {
    this.scLogin = false;
    this.scRecovery = false;
    this.scRecoveryCode = false;
    this.scRecoveryPass = true;
  }

  async sendRecovery() {
    if (await this.validateRec()) {
      this.loadinService.show();
      this.formData.email = this.formData.emailrec.trim();
      let result: any = await this.loginService.sendCode(this.formData);
      result = result.json();
      if (result.code_valid) {
        this.recoveryCode();
      }

      this.loadinService.hide();
    }
  }

  async validCode() {
    if (await this.validateCode()) {
      this.loadinService.show();
      this.formData.email = this.formData.emailrec;
      this.formData.cod = this.formData.recovery;
      let result: any = await this.loginService.validaCode(this.formData);
      result = result.json();
      if (result.validate === true) {
        this.recoveryPass();
      } else {
        this.toastr.error('Codigo invalido.');
      }

      this.loadinService.hide();
    }
  }

  async validateRec() {

    if (!this.formData.emailrec) {
      this.toastr.error('E-mail é obrigatorio!');
      return false;
    }
    if (!await this.IsEmail(this.formData.emailrec)) {
      this.toastr.error('O e-mail informado é inválido.');
      return false;
    }
    return true;
  }

  async validateCode() {
    if (!this.formData.recovery) {
      this.toastr.error('Código é obrigatorio!');
      return false;
    }
    return true;
  }


  async reset() {
    this.loadinService.show();
    if (await this.validatePass()) {
      this.formData.password = this.formData.senharec;
      let result: any = await this.loginService.reset(this.formData);
      result = result.json();
      if (result.validate === true) {
        this.toastr.success('Senha alterada com sucesso.');
        this.openLogin();
      } else {
        this.toastr.error('Não foi possivel alterar sua senha.');
      }
    }

    this.loadinService.hide();
  }

  async validatePass() {
    if (!this.formData.senharec) {
      this.toastr.error('O campo senha é obrigatorio!');
      return false;
    }
    if (!this.formData.confSenha) {
      this.toastr.error('O campo confirmar senha é obrigatorio!');
      return false;
    }
    if (this.formData.senharec !== this.formData.confSenha) {
      this.toastr.error('O campo senha e confirmar senha devem ser iguais!');
      return false;
    }
    return true;
  }

  async IsEmail(email) {
    const er = new RegExp(/^[A-Za-z0-9_\-\.]+@[A-Za-z0-9_\-\.]{2,}\.[A-Za-z0-9]{2,}(\.[A-Za-z0-9])?/);
    return er.test(email);
  }

  async retornaLista(page) {
      this.list = {
          docs:[]
      };
      var params = {
          page: null,
          codigo_ref: this.userInfo.user.codigo,
      };
      params.page = page;
      let result:any = await this.loginService.findRevenda(params, this.userInfo.token);
      if (result._body) {
          this.list = JSON.parse(result._body)
      }
  }

}
